<template>
  <div class="total-card">
    <div class="total-card-left">
      <div class="total-card-label">{{ label }}</div>
      <div class="total-card-value" v-if="value">{{ value }}</div>
      <div class="total-card-slot" v-else>
        <slot></slot>
      </div>
    </div>
    <div class="total-card-right">
      <a block class="total-card-icon" @click="$emit('icon')">
        <iconic :name="icon" flex-center />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: ["label", "value", "icon"],
};
</script>

<style lang="scss" scoped>
.total-card {
  box-shadow: 0 20px 27px 0 #0000000d;
  background-color: #fff;
  background-clip: border-box;
  border-radius: $mpadding;
  padding: $mpadding * 1.2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &-left {
    flex-direction: column;
  }
  &-icon {
    padding: $mpadding;
    background-image: linear-gradient(310deg, #ff5858, $primary_color);
    color: #fff;
    font-size: 150%;
    border-radius: $mpadding/2;
  }
  &-label {
    font-size: 100%;
    font-weight: bold;
    margin: 0 0 $mpadding/4 0;
    color: rgb(103, 116, 142);
  }
  &-value {
    font-size: 150%;
    font-weight: 900;
    border-radius: $mpadding/2;
  }
}
</style>
